import Config from '../config';
import {updateObject} from '../utils/dataFunctions';

const INITIAL_STATE = {
    notification_message: '',
    notification_open: false,
    notification_type: 'info',
    sidebarOpen: true,
    tokens: Config.tokensDefault,
    urls: Config.urls
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOG_OUT':
            let tokens = updateObject(state.tokens, Config.tokensDefault);
            return {
                ...state,
                tokens: tokens
            };
        case 'SET_AUTHENTICATED':
            return {
                ...state,
                authenticated: action.authenticated,
            };
        case 'SET_SIDEBAR_VISIBILITY':
            return {
                ...state,
                sidebarOpen: action.payload
            };
        case 'SET_TOKENS':
            return {
                ...state,
                tokens: action.tokens
            };
        case 'SET_URLS':
            let urls = updateObject(state.urls, action.urls);
            return {
                ...state,
                urls: urls
            };
        case 'SHOW_NOTIFICATION':
            return {
                ...state,
                notification_type: action.payload.type,
                notification_message: action.payload.message,
                notification_open: action.payload.open
            };
        case 'TOGGLE_SIDEBAR':
            return {
                ...state,
                sidebarOpen: !state.sidebarOpen,
            };
        default:
            return state;
    }
};

export default reducer;
