import {
    HIDE_NOTIFICATION,
    LOG_OUT,
    SET_AUTHENTICATED,
    SET_SIDEBAR_VISIBILITY,
    SET_TOKENS,
    SET_URLS,
    SHOW_NOTIFICATION,
    TOGGLE_SIDEBAR
} from './actionTypes';

// Auth / User

export const setAuthenticated = authenticated => ({
    type: SET_AUTHENTICATED,
    authenticated
});

export const setTokens = tokens => {
    return {
        type: SET_TOKENS,
        tokens
    };
};

export function logOutUser() {
    return {
        type: LOG_OUT
    }
}

// UI

export const setSidebarVisibility = isOpen => ({
    type: SET_SIDEBAR_VISIBILITY,
    payload: isOpen,
});

export const setUrls = urls => {
    return {
        type: SET_URLS,
        urls
    };
};

export const showNotification = (type, message, open) => {
    return {
        type: SHOW_NOTIFICATION,
        payload: {
            type: type,
            message: message,
            open: open
        }
    }
};

export const hideNotification = () => {
    return {
        type: HIDE_NOTIFICATION
    }
};

export const toggleSidebar = () => ({
    type: TOGGLE_SIDEBAR,
});
