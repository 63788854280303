import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';

class LoggedOut extends Component {

    handleLoginClick = () => {
        this.props.keycloak.login({
            action: 'login',
            redirectUri: window.location.href,
        });
    };

    render() {
        return (
            <>
                <div className="content about">
                    <h1 className="page-title">
                        Please Log In
                    </h1>
                    <p>
                        <button className="btn btn-primary"
                                onClick={this.handleLoginClick}
                                title="Sign In"
                        >
                            Sign In
                        </button>
                    </p>
                </div>
            </>
        )
    }
}

export default withKeycloak(LoggedOut);
