import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {logOutUser} from '../store/actions';
import {checkAuthentication} from '../utils/authFunctions';
import XrathusLogoHorizontal from '../partials/images/XrathusLogoHorizontal';
import '../css/top-bar.css';

class AppHeader extends Component {

    handleLoginClick = () => {
        this.props.keycloak.login({
            action: 'login',
            redirectUri: window.location.href,
        });
    };

    handleLogout = () => {
        this.props.logOutUser();
        this.props.keycloak.logout();
    };

    render() {
        const {
            keycloak,
            sidebarOpen,
            tokens
        } = this.props;

        const isAuthenticated = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);
        const sidebarClass = (sidebarOpen) ? ' sidebar-open' : '';

        return (
            <>
                <div className={`top-bar${sidebarClass}`}>

                    {!sidebarOpen &&
                    <Link to="/" title="Xrathus">
                        <XrathusLogoHorizontal/>
                    </Link>
                    }
                    {sidebarOpen &&
                    <div>
                    </div>
                    }

                    <ul className="nav-top">
                        {!isAuthenticated &&
                        <li>
                            <button className="link"
                                    onClick={this.handleLoginClick}
                                    title="Sign In"
                            >
                                Sign In
                            </button>
                        </li>
                        }
                        {isAuthenticated &&
                        <li>
                            <button className="link"
                                    onClick={this.handleLogout}
                                    title="Sign Out"
                            >
                                Sign Out
                            </button>
                        </li>
                        }
                    </ul>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    let sidebarOpen = state.sidebarOpen;
    let tokens = state.tokens;
    if (tokens && sidebarOpen !== null && sidebarOpen !== undefined) {
        return {
            sidebarOpen: sidebarOpen,
            tokens: {
                token: tokens.token
            }
        };
    }
    return {};
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps,
            {logOutUser}
        )(AppHeader)
    )
);
