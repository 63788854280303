export default {
    version: '0.1.1',
    language: 'en-US',
    routes: [
        '/x/challenges',
        '/x/orgs',
        '/x/users',
        '/',
    ],
    tokensDefault: {
        idToken: null,
        token: null,
        refreshToken: null
    },
    urls: {
        api_url: '',
        forum_url: '',
        lab_url: ''
    },
};
