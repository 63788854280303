import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import RouteHandler from '../RouteHandler';
import {setSidebarVisibility} from '../store/actions';
import AppHeader from './Header';
import AppSidebar from './Sidebar';

function AppLayout(props) {

    const onMenuClose = () => {
        props.setSidebarVisibility(false);
    };

    return (
        <>
            <AppSidebar onMenuClose={onMenuClose}/>
            <div className={(props.sidebarOpen) ? 'page sidebar-open' : 'page'}>
                <AppHeader/>
                <RouteHandler/>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    let sidebarOpen = state.sidebarOpen;
    if (sidebarOpen !== null && sidebarOpen !== undefined) {
        return {
            sidebarOpen: sidebarOpen
        };
    }
    return {};
};

AppLayout.propTypes = {
    configParams: PropTypes.object,
    sidebarOpen: PropTypes.bool,
};

export default withRouter(
    connect(
        mapStateToProps,
        {setSidebarVisibility: setSidebarVisibility}
    )(AppLayout)
);
