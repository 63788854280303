import React, {Component} from 'react';

class Challenges extends Component {
    render() {
        return (
            <>
                <div className="content challenges">
                    <h1 className="page-title">
                        Manage Challenges
                    </h1>
                </div>
            </>
        )
    }
}

export default Challenges;
