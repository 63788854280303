/**
 * Add a class to the body
 *
 * @param classes
 */
export const setBodyClass = (classes) => {
    clearBodyClasses();
    document.body.classList.add(...classes);
};

/**
 * Remove all classes from body
 */
const clearBodyClasses = () => {
    let classList = document.body.classList;
    while (classList.length > 0) {
        classList.remove(classList.item(0));
    }
};

export const scrollToElement = (ref) => window.scrollTo(0, ref.current.offsetTop);
