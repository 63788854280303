// Auth
export const LOG_OUT = 'LOG_OUT';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_TOKENS = 'SET_TOKENS';
export const SET_URLS = 'SET_URLS';

// UI
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
