import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {KeycloakProvider} from '@react-keycloak/web';
import {BrowserRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {setTokens, setUrls} from './store/actions';
import AppLayout from './containers/Layout';

class App extends Component {

    componentDidMount() {
        const params = this.props.configParams;
        const urls = {
            api_url: params.api_url,
            forum_url: params.forum_url,
            lab_url: params.lab_url
        };
        this.props.setUrls(urls);
    }

    /**
     * Process auth events from Keycloak
     *
     * @param event
     * @param error
     */
    handleKeycloakEvent = (event, error) => {
        // console.log('Keycloak event: ', event);
        // console.log('KC event error: ', error);
        if (event === 'onAuthSuccess') {
            // @TODO redirect upon new login
            // const destination = localStorage.getItem('destination');
            // if (!destination) {
            //     console.log('onAuthSuccess with no destination! Redirect!... ', destination);
            // }
            // console.log('Auth success');
        }
        if (event === 'onAuthRefreshSuccess') {
            // console.log('Auth Refresh success');
        }
        if (event === 'onTokenExpired') {
            // console.log('Auth Token expired');
        }
        if (event === 'onAuthLogout') {
            console.log('Auth logout');
        }
    };

    /**
     * Process tokens event from Keycloak
     *
     * @param event
     * @param error
     */
    handleKeycloakTokens = (event, error) => {
        // console.log('Keycloak token event: ', event);
        // console.log('Keycloak token error: ', error);
        if (event && (event.token || event.refreshToken)) {
            const tokens = {
                token: event.token,
                idToken: event.idToken,
                refreshToken: event.refreshToken,
            };
            // console.log('Store Token Redux');
            this.props.setTokens({token: tokens.token});
        } else if (error) {
            console.log('Keycloak token error: ', error);
        }
    };

    render() {
        const {
            configParams,
            keycloak,
            keycloakInitConfig
        } = this.props;

        return (
            <KeycloakProvider
                keycloak={keycloak}
                initConfig={keycloakInitConfig}
                onEvent={this.handleKeycloakEvent}
                onTokens={this.handleKeycloakTokens}
            >
                <BrowserRouter>
                    <AppLayout configParams={configParams}/>
                </BrowserRouter>
            </KeycloakProvider>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        tokens: {
            token: state.tokens.token,
            idToken: state.tokens.idToken,
            refreshToken: state.tokens.refreshToken
        }
    };
};

const mapDispatchToProps = {
    setTokens: setTokens,
    setUrls: setUrls
};

App.propTypes = {
    configParams: PropTypes.object,
    keycloak: PropTypes.any,
    keycloakInitConfig: PropTypes.object,
    tokens: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
