/**
 * Determine if current runtime is local
 *
 * @type {boolean}
 */
export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

/**
 * Load JSON file with environment variables
 *
 * @return {Promise<unknown>}
 */
export async function getEnvironmentConfig() {
    return fetch('/config.json')
        .then((response) => response.json())
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

/**
 * Update an object via Object.assign
 *
 * @param oldObject
 * @param newValues
 * @return {any}
 */
export const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues)
};

/**
 * Convert bytes to a human-readable string
 *
 * @param bytes
 * @return {string}
 */
export const convertBytesToString = (bytes) => {
    let i = Math.floor(Math.log(bytes) / Math.log(1024)), sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
};

/**
 * Add commas to numerical strings
 *
 * @param nStr
 * @return {string}
 */
export const addCommas = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1,$2');
    }
    return x1 + x2;
};
