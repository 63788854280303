import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import Config from '../config';

const initialState = {
    notification_message: '',
    notification_open: false,
    notification_type: 'info',
    sidebarOpen: true,
    tokens: Config.tokensDefault,
    urls: Config.urls
};

// Create a Redux store with initial state, Redux-Thunk and DevTools
const store = createStore(
    reducer,
    initialState,
    compose(
        applyMiddleware(thunk),
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) || compose
    )
);

export default store;
