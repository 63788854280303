import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {
    Redirect,
    Route,
    Switch,
    withRouter
} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from './utils/authFunctions';
import {setBodyClass} from './utils/pageFunctions';
import Config from './config';

import Home from './routes/Home';
import LoggedOut from './routes/LoggedOut';
import NotFound from './routes/NotFound';
import Challenges from './routes/Challenges';
import Orgs from './routes/Orgs';
import Users from './routes/Users';

/**
 * Return Route or Redirect based on authentication status
 *
 * @param Component
 * @param auth
 * @param exact
 * @param redirect
 * @param rest
 * @return {*}
 * @constructor
 */
const PrivateRoute = ({
                          component: Component,
                          render: Function,
                          auth,
                          redirect,
                          ...rest
                      }) => {
    return (
        <Route
            {...rest}
            render={props => (
                auth === true ? (
                    <Component {...props}/>
                ) : (
                    <Redirect
                        to={{
                            pathname: redirect,
                            state: {from: props.location}
                        }}/>
                )
            )}/>
    )
};

/**
 * Class for rendering Routes and PrivateRoutes
 *
 */
class RouteHandler extends Component {

    /**
     * Set body classes from listed routes
     *
     * @param location
     */
    static setPathElements(location) {
        if (location) {
            let pathname = location.pathname;
            let hash = location.hash;
            let classArr = [];
            if (pathname && pathname.indexOf('/') !== -1) {
                let pathArray = pathname.split('/');
                if (pathArray[1] === 'x') {
                    // Add all path elements
                    for (let p = 2; p < pathArray.length; p++) {
                        classArr.push(pathArray[p].replace('%20', '-'));
                    }
                } else {
                    // For Pages without /x/, use the first value
                    classArr.push(pathArray[1]);
                    for (let p = 1; p < pathArray.length - 1; p++) {
                        classArr.push(pathArray[p].replace('%20', '-'));
                    }
                }
                // Add any hash elements
                let hashArray = hash.split('/');
                for (let h = 1; h < hashArray.length; h++) {
                    classArr.push(hashArray[h].replace('%20', '-'));
                }
            } else {
                classArr.push(pathname);
            }

            // Set a class for the body based on route
            if (classArr[0] === '') {
                classArr = ['home'];
            } else if (Config.routes.findIndex(r => r === '/x/' + classArr[0]) === -1) {
                classArr.push('not-found');
            }

            setBodyClass(classArr);
        }
    }

    render() {
        const {
            keycloak,
            location,
            tokens
        } = this.props;

        RouteHandler.setPathElements(location);
        const isAuthenticated = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);

        return (
            <Switch>

                <Route path="/x/about" component={Home}/>

                <PrivateRoute path="/x/challenges" component={Challenges} auth={isAuthenticated} redirect='/'/>
                <PrivateRoute path="/x/orgs" component={Orgs} auth={isAuthenticated} redirect='/'/>
                <PrivateRoute path="/x/users/workspace" component={Users} auth={isAuthenticated} redirect='/'/>
                <PrivateRoute path="/x/users/:sub" component={Users} auth={isAuthenticated} redirect='/'/>
                <PrivateRoute path="/x/users" component={Users} auth={isAuthenticated} redirect='/'/>
                <PrivateRoute path="/x/logout" component={LoggedOut} auth={!isAuthenticated} redirect='/'/>

                <Route exact path="/" component={Home}/>

                <Route path="*" component={NotFound}/>
            </Switch>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tokens: {
            token: state.tokens.token
        }
    };
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps
        )(RouteHandler)
    )
);
