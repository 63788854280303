import React, {Component} from 'react';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {checkAuthentication} from '../utils/authFunctions';

class Home extends Component {

    handleLoginClick = () => {
        this.props.keycloak.login({
            action: 'login',
            redirectUri: window.location.href,
        });
    };

    render() {
        const {
            keycloak,
            tokens
        } = this.props;

        const isAuthenticated = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);

        return (
            <>
                <div className="content home">
                    <h1 className="page-title">
                        Xrathus Admin
                    </h1>
                    {!isAuthenticated &&
                    <>
                        <p>Please Sign In.</p>
                        <p>
                            <button className="btn btn-primary"
                                    onClick={this.handleLoginClick}
                                    title="Sign In"
                            >
                                Sign In
                            </button>
                        </p>
                    </>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    let tokens = state.tokens;
    if (tokens !== null) {
        return {
            tokens: {
                token: tokens.token
            }
        };
    }
    return {};
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps,
            null
        )(Home)
    )
);
