import React, {Component} from 'react';

class Users extends Component {
    render() {
        return (
            <>
                <div className="content users">
                    <h1 className="page-title">
                        Manage Users
                    </h1>
                </div>
            </>
        )
    }
}

export default Users;
