import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withKeycloak} from '@react-keycloak/web';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {setSidebarVisibility} from '../store/actions';
import {checkAuthentication} from '../utils/authFunctions';
import XrathusLogoHorizontal from '../partials/images/XrathusLogoHorizontal';
import '../css/sidebar.css';

class AppSidebar extends Component {

    linkIsActive = (link) => {
        const path = this.props.location.pathname;
        if (path && path !== '/') {
            if ((path === link) || (link.indexOf(path) !== -1)) {
                return 'active';
            }
        }
        return '';
    };

    render() {
        const {
            keycloak,
            sidebarOpen,
            tokens
        } = this.props;

        const isAuthenticated = checkAuthentication(tokens.token, keycloak.token, keycloak.authenticated);
        const openClass = (sidebarOpen) ? 'open' : 'closed';

        return (
            <>
                <div className={`sidebar ${openClass}`}>
                    <div className="logo">
                        <Link to="/" title="Xrathus">
                            <XrathusLogoHorizontal/>
                        </Link>
                    </div>

                    <h2>
                        <i className="fa fa-arrow-right" aria-hidden="true"/>
                        Admin
                    </h2>

                    {isAuthenticated &&
                    <ul className="nav-sidebar">
                        <li>
                            <Link to="/x/users" title="Users"
                                  className={this.linkIsActive('/x/users')}>Users</Link>
                        </li>
                        <li>
                            <Link to="/x/orgs" title="Orgs"
                                  className={this.linkIsActive('/x/orgs')}>Orgs</Link>
                        </li>
                        <li>
                            <Link to="/x/challenges" title="Challenges"
                                  className={this.linkIsActive('/x/challenges')}>Challenges</Link>
                        </li>
                    </ul>
                    }
                </div>
            </>
        )
    }
}

/*
<button onClick={(e) => this.func(e)} className="sidebar-button">Text</button>
<a href={item.route} title={item.title} target="_blank" rel="noopener noreferrer">{item.title}</a>
 */

const mapStateToProps = (state) => {
    let sidebarOpen = state.sidebarOpen;
    let tokens = state.tokens;
    if (tokens && sidebarOpen !== null && sidebarOpen !== undefined) {
        return {
            sidebarOpen: sidebarOpen,
            tokens: {
                token: tokens.token
            }
        };
    }
    return {
        sidebarOpen: sidebarOpen,
        tokens: {
            token: null
        }
    };
};

const mapDispatchToProps = {
    setSidebarVisibility: setSidebarVisibility
};

AppSidebar.propTypes = {
    onMenuClose: PropTypes.func,
    sidebarOpen: PropTypes.bool
};

export default withKeycloak(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AppSidebar)
    )
);
